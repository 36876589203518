import { createContext, useEffect, useState } from 'react';

import TagManager from 'react-gtm-module';

const GTMContext = createContext();

const GTMContextProvider = (props) => {
  // state
  const [dataLayer, setDataLayer] = useState({});
  // effect
  useEffect(() => {
    // initialize google tag manager
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_KEY,
    });
  }, []);
  useEffect(() => {
    // push dataLayer when it's changed
    TagManager.dataLayer({
      dataLayer: { ...dataLayer },
    });
    console.log('Invoking | GTM Enabled');
  }, [dataLayer]);
  // render
  return (
    <GTMContext.Provider
      value={{
        dataLayer,
        setDataLayer,
      }}
    >
      {props.children}
    </GTMContext.Provider>
  );
};

export { GTMContextProvider, GTMContext };
