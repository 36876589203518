import { init } from '@web3-onboard/react';

import coinbaseModule from '@web3-onboard/coinbase';
import injectedModule from '@web3-onboard/injected-wallets';

import walletConnectModule from '@web3-onboard/walletconnect';

import { CHAIN } from '@/utils/helpers';

//New version requires modules to be imported for each wallet
const injected = injectedModule();
const coinbase = coinbaseModule();

const walletConnect = walletConnectModule({
  projectId: process.env.NEXT_PUBLIC_WALLETCONNECT_ID,
  version: 2,
  handleUri: (uri) => console.log(uri),
  requiredChains: [process.env.NEXT_PUBLIC_WEB3AUTH_CHAIN_ID],
  dappUrl: 'https://www.unicornparty.com/',
});

export const initWeb3Onboard = init({
  //Wallets display in the order of this array
  wallets: [coinbase, injected, walletConnect],
  connect: {
    autoConnectLastWallet: true,
    // iDontHaveAWalletLink: torusModule().link,
  },

  //New version requires each chain that the app supports
  //to be defined
  chains: [
    {
      id: '0x89',
      token: 'MATIC',
      label: 'Polygon',
      rpcUrl: CHAIN.POLYGON,
    },
    {
      id: '0x13882',
      token: 'MATIC',
      label: 'Amoy',
      rpcUrl: CHAIN.MATIC,
    },
  ],

  appMetadata: {
    name: 'Unicorn Party',
    icon: 'https://www.unicornparty.com/footer-logo.png',
    logo: 'https://www.unicornparty.com/footer-logo.png',
    description: 'Unicorn Party Games',
    explore: 'https://www.unicornparty.com/',
    /*
    recommendedInjectedWallets: [
      { name: 'Coinbase', url: WALLET.COINBASE },
      { name: 'MetaMask', url: WALLET.METAMASK },
    ],
    */
  },
  accountCenter: {
    desktop: {
      position: 'topRight',
      enabled: false,
      minimal: false,
    },
    mobile: {
      position: 'topRight',
      enabled: false,
      minimal: true,
    },
  },
  i18n: {
    en: {
      connect: {
        selectingWallet: {
          header: '🦄 Welcome to the Unicorn Party',
          sidebar: {
            heading: '',
            subheading: 'Select your wallet from the options to get started',
            paragraph: 'Unicorn Party recommends the Metamask Wallet.',
          },
        },
      },
    },
  },
});
