import { utils } from 'ethers';

const weiDecimals = 18;
const usdcDecimals = 6;
const usdcPower = 10 ** 6;
const weiPower = 10 ** 6;
const maxApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
const confirmations = 2;
const power = 10 ** 6;

const CHAIN = {
  MATIC: "https://80002.rpc.thirdweb.com",
  POLYGON: "https://polygon-rpc.com",
};

const WALLET = {
  COINBASE: 'https://wallet.coinbase.com/',
  METAMASK: 'https://metamask.io',
};

function mutezToTez(mutez) {
  return mutez / power;
}

function tezToMutez(tez) {
  return tez * power;
}

function weiToEtherWithDecimals(weiAmount, decimalPlaces = 18) {
  const ether = utils.formatUnits(weiAmount, decimalPlaces);
  return ether.toString();
}

function etherToWeiWithDecimals(etherAmount, decimalPlaces = 18) {
  const wei = utils.parseUnits(etherAmount, decimalPlaces);
  return wei.toString();
}

export {
  weiToEtherWithDecimals,
  etherToWeiWithDecimals,
  weiDecimals,
  usdcDecimals,
  usdcPower,
  weiPower,
  maxApprove,
  confirmations,
  mutezToTez,
  tezToMutez,
  CHAIN,
  WALLET,
};
