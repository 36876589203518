import { useEffect, useState } from 'react';
import { initWeb3Onboard } from './Wallets';

const ConnectWallet = ({ wallet, connect }) => {
  const [, /*web3Onboard*/ setWeb3Onboard] = useState(null);

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, []);
};
export default ConnectWallet;
