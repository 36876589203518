import ConnectWallet from '@/components/ConnectWallet';
import { GTMContextProvider } from '@/context/GTMContext';
import '@/styles/main.scss';
import { useConnectWallet } from '@web3-onboard/react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
const WalletSCR = dynamic(() => import('@/components/WalletSCR'), { ssr: false });
const Footer = dynamic(() => import('@/components/Footer'));
const Navbar = dynamic(() => import('@/components/Navbar'));

export default function App({ Component, pageProps }) {
  const [{ wallet }, connect] = useConnectWallet();
  const router = useRouter();
  ConnectWallet({ wallet, connect });

  return (
    <>
      <Head>
        <title>Unicorn Party</title>
        <meta name="description" content="Unicorn Party" />
        <meta http-equiv="Accept-CH" content="Sec-CH-UA-Bitness" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/logo.png" />
        <meta property="og:title" content="Unicorn Party Gaming Arcade" />
        <meta
          property="og:description"
          content="Unlock your gaming skills and win big at Unicorn Party! Challenge yourself and thrive against others. Start playing and winning now!"
        />
        <meta property="og:image" content="https://unicornparty.com/web_preview.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Unlock your gaming skills and win big at Unicorn Party! Challenge yourself and thrive against others. Start playing and winning now!"
        />
        <meta name="twitter:title" content="Unicorn Party Gaming Arcade" />
        <meta name="twitter:site" content="" />
        <meta name="twitter:creator" content="" />
      </Head>
      <GTMContextProvider>
        <div className="content" id="overAllPage">
          {!pageProps?.amount ? (
            <>
              <Navbar />
              <main>
                <Component {...pageProps} />
                {router.pathname !== '/landing' &&
                  router.pathname !== '/landingpage' &&
                  router.pathname !== '/wertpurchase' && <WalletSCR />}
              </main>
              <Footer />
            </>
          ) : (
            <Component {...pageProps} />
          )}
        </div>
      </GTMContextProvider>
    </>
  );
}
